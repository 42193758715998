tailwind.config = {
    prefix: 'st-',
    corePlugins: {
        preflight: false,
    },
    theme: {
        fontWeight: { //st-font-regular
            regular: '400',
            medium: '500',
            bold: '700'
        },
        fontSize: { //st-text-12
            '12': '12px',
            '14': '14px',
            '16': '16px',
            '18': '18px',
            '20': '20px',
            '24': '24px',
            '28': '28px',
            '32': '32px',
            '42': '42px',
            '68': '68px',
        },
        borderRadius: { //st-rounded-8
            '0': '0px',
            '2': '2px',
            '4': '4px',
            '8': '8px',
            '16': '16px',
            '32': '32px',
            'circle': '50%'
        },
        extend: {
            fontFamily: {
                'noto-sans-tc': ['Noto Sans TC', 'sans-serif'],
                'roboto-flex': ["Roboto Flex", "sans-serif"]
            },
            lineHeight: { //st-leading-xs
                xs: '1',
                sm: '1.25',
                md: '1.5',
                lg: '1.75'
            },
            boxShadow: { //st-shadow-dropdown
                'sm': '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1)',
                DEFAULT: '0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 0px 3px rgba(0, 0, 0, 0.16)',
                'md': '0px 0px 4px -1px rgba(0, 0, 0, 0.08), 0px 0px 6px -1px rgba(0, 0, 0, 0.16)',
                'lg': '0px 0px 1px -4px rgba(0, 0, 0, 0.12), 0px 2px 12px -3px rgba(0, 0, 0, 0.2)',
                'xl': '0px 8px 10px -6px rgba(0, 0, 0, 0.16), 0px 2px 20px -5px rgba(0, 0, 0, 0.2)',
                '2xl': '0px 12px 10px -6px rgba(0, 0, 0, 0.2), 0px 4px 50px -12px rgba(0, 0, 0, 0.2)',
                'upper': '0px -2px 6px rgba(0, 0, 0, 0.12)',
                'inner': 'inset 0px 2px 4px 1px rgba(0, 0, 0, 0.08)',
                'none': '0 0 #0000',
                'dropdown': '1px 4px 8px 2px rgba(0, 0, 0, 0.12)',
                'modal': '0px -2px 8px 4px rgba(0, 0, 0, 0.08), 1px 3px 8px 2px rgba(0, 0, 0, 0.16)'
            },
            colors: { // st-text-primary | st-bg-primary | st-border-primary | st-bg-green-50
                'primary': '#171725',
                'neutral': '#92929D',
                'white': '#FFFFFF',
                'black': '#000000',
                'success': '#17AA6F',
                'warning': '#FFBA1D',
                'danger': '#EC4E4E',
                'info': '#1355C1',
                'brand-light': '#38B0E3',
                'brand-fitness': '#1355C1',
                'brand-wellness': '#17AA6F',
                'secondary': {
                    DEFAULT: '#38B0E3',
                    10: '#B3D4EB',
                    20: '#A4CCE7',
                    30: '#95C2E3',
                    40: '#87BADF',
                    50: '#38B0E3',
                    60: '#6CA9D7',
                    70: '#5FA1D4',
                    80: '#5398D0',
                    90: '#4A8EC6',
                },
                'gray': {
                    90: '#696974',
                    50: '#B5B5BE',
                    40: '#D5D5D5',
                    30: '#E2E2EA',
                    20: '#F1F1F5',
                    10: '#FAFAFB'
                },
                'green': {
                    DEFAULT: '#17AA6F',
                    10: '#85E5B6',
                    20: '#7CE4AE',
                    30: '#73E1A5',
                    40: '#6DDF9D',
                    50: '#65D494',
                    60: '#5CC287',
                    70: '#17AA6F',
                    80: '#4BA06F',
                    90: '#438E61',
                },
                'yellow': {
                    DEFAULT: '#FFBA1D',
                    10: '#F8D67F',
                    20: '#F7D06F',
                    30: '#F6CA60',
                    40: '#F6C453',
                    50: '#FFBA1D',
                    60: '#F4B942',
                    70: '#EAAE3C',
                    80: '#D6A036',
                    90: '#C39231',
                },
                'red': {
                    DEFAULT: '#EC4E4E',
                    10: '#E9A7A5',
                    20: '#E59592',
                    30: '#E28481',
                    40: '#DF736F',
                    50: '#EC4E4E',
                    60: '#DA534D',
                    70: '#D7463E',
                    80: '#D5392F',
                    90: '#CC3328',
                },
                'blue': {
                    DEFAULT: '#1355C1',
                    10: '#759DEA',
                    20: '#5583E6',
                    30: '#4677E3',
                    40: '#386BE1',
                    50: '#2F61D7',
                    60: '#1355C1',
                    70: '#2751B3',
                    80: '#2349A1',
                    90: '#1F4190',
                },
                // TODO REMOVE COLOR, DO NOT USE COLOR SYSTEM LIST BELOW 
                'brand-sport': { // TODO REMOVE
                    DEFAULT: '#38B0E3',
                    10: '#B3D4EB',
                    20: '#A4CCE7',
                    30: '#95C2E3',
                    40: '#87BADF',
                    50: '#38B0E3',
                    60: '#6CA9D7',
                    70: '#5FA1D4',
                    80: '#5398D0',
                    90: '#4A8EC6',
                },
                'brand-beauty': { // TODO REMOVE
                    DEFAULT: '#C32951',
                    10: '#D57C95',
                    20: '#D06F87',
                    30: '#CA5F7A',
                    40: '#C8516E',
                    50: '#C34360',
                    60: '#C32951',
                    70: '#AA354F',
                    80: '#9A3048',
                    90: '#8B2B41',
                },
            }
        }
    }
}